import React, { useMemo } from "react"
import moment from "moment"
import { DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledDropdown, CustomInput } from "reactstrap"
import { useTable, useSortBy, usePagination } from "react-table"

import AddToBook from "../book/addToBook"
import PassageTokens from "../library/passageTokens"
import { Annotated, Passage, fetchPassagesForLibraryAction, updatePassageIsCompleteAction } from "../../hasura/slices/passage"
import { Cell, Header, HeaderCell, Table, TextCell, isColumn } from "../common/tables/table"
import { Concepts_concepts } from "../../hasura/queries/types/Concepts"
import { Books_books } from "../../hasura/queries/types/Books"
import { useDispatch, useSelector } from "react-redux"
import { UserState, userSelector } from "../../hasura/slices/user"
import { CurriculumState, curriculumSelector } from "../../hasura/slices/curriculum"

// @ts-ignore
import dots from "../../lib/images/dots.svg"

const defaultColumn: any = {
  Cell: TextCell,
}

interface Props {
  data: Passage[]
  concepts: Concepts_concepts[]
  books: Books_books[]
  handleDeletePassage: (id: number) => void
}

const PAGE_SIZE = 100
const passageBookCount = (p: Passage, books: Books_books[]) => books.filter((b) => p.concepts_denormalized?.includes(b.seed)).length

export default function PassagesTable(props: Props) {
  const dispatch = useDispatch()

  const { accessToken }: UserState = useSelector(userSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const columns = useMemo(
    () => [
      {
        Header: "Completed",
        accessor: (p: Passage) => p,
        disableSortBy: true,
        Cell: (p: { value: Passage }) => {
          return (
            <div className="w-100 flex-center">
              <CustomInput
                onChange={async () => {
                  if (!accessToken || !curriculum) return

                  await dispatch(updatePassageIsCompleteAction(accessToken, p.value.id, !p.value.is_complete))
                  dispatch(fetchPassagesForLibraryAction(accessToken, curriculum.id))
                }}
                type="checkbox"
                id={`completed-${p.value.id}`}
                checked={p.value.is_complete}
              />
            </div>
          )
        },
      },
      {
        Header: "Updated",
        accessor: (p: Passage) => moment(p.updated_at).format("M-D-YY"),
      },
      {
        Header: "Text",
        accessor: (p: Passage) => p,
        disableSortBy: true,
        Cell: (p: { value: Passage }) => {
          return (
            <div className="text-s">
              {p.value.annotated ? (
                <PassageTokens annotated={p.value.annotated as Annotated} concepts={props.concepts} />
              ) : (
                `${p.value.original_text_sentence_tokenized.join("\n").slice(0, 200)}...`
              )}
            </div>
          )
        },
      },
      {
        Header: "# Images",
        accessor: (p: Passage) => p.illustrated_passages.length,
        sortDescFirst: true,
      },
      {
        Header: "# Books",
        accessor: (p: Passage) => passageBookCount(p, props.books),
        sortDescFirst: true,
      },
      {
        Header: "# Questions",
        accessor: (p: Passage) => p,
        sortDescFirst: true,
        Cell: (p: { value: Passage }) => {
          return (
            <div
              onClick={() => window.open(`${window.location.origin}/play?type=test&ids=${p.value.questions.map((q) => q.id).join()}`, "_blank")}
              className="text-s text-link"
            >
              {p.value.questions.length}
            </div>
          )
        },
      },
      {
        Header: "# Custom Questions",
        accessor: (p: Passage) => (p.custom_questions || []).length,
        sortDescFirst: true,
      },
      {
        Header: "Books",
        accessor: (p: Passage) => p,
        disableSortBy: true,
        Cell: (p: { value: Passage }) => {
          return (
            <div className="d-flex align-items-start ">
              <div className="flex-grow">
                <AddToBook
                  passage={p.value}
                  onDone={() => {
                    if (!accessToken || !curriculum) return

                    dispatch(fetchPassagesForLibraryAction(accessToken, curriculum.id))
                  }}
                  menuPlacement="bottom"
                />
              </div>

              <UncontrolledDropdown className="dropdown-menu-dots mx-2" onClick={(e: any) => e.stopPropagation()}>
                <DropdownToggle>
                  <img className="icon-s" src={dots} />
                </DropdownToggle>

                <DropdownMenu className="p-2" right>
                  <DropdownItem header className="mb-1 p-2 p-0 text-xs bold">
                    More actions
                  </DropdownItem>

                  <DropdownItem className="mb-2 p-0" onClick={() => window.open(`${window.location.origin}/tagging?id=${p.value.id}`, "_blank")}>
                    Edit
                  </DropdownItem>

                  <DropdownItem className="mb-2 p-0" onClick={() => props.handleDeletePassage(p.value.id)}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [curriculum, props.concepts]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    state: { pageIndex },
  } = useTable(
    {
      // @ts-ignore
      columns,
      data: props.data,
      defaultColumn,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        // @ts-ignore
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        sortBy: [{ id: "Created", desc: true }],
      },
    },
    useSortBy,
    usePagination
  )

  const paginate = (
    <div className={`d-flex align-items-center justify-content-end w-100 ${props.data.length > 100 ? "" : "hidden"}`}>
      <Button onClick={() => previousPage()} disabled={!canPreviousPage} size="sm" outline color="primary">
        Back
      </Button>

      <p className="my-0 mx-2">
        Page {pageIndex + 1} of {pageOptions.length}
      </p>

      <Button onClick={() => nextPage()} disabled={!canNextPage} size="sm" outline color="primary">
        Next
      </Button>
    </div>
  )

  const colSpan = (data: any) => (isColumn(data, "Text") || isColumn(data, "Books") ? 3 : 1)

  return (
    <div style={{ marginTop: "-25px" }}>
      {paginate}

      <Table style={{ maxWidth: "1300px" }} className="w-100 mb-3" cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, idx: number) => (
                <HeaderCell noBorder key={idx} {...column.getHeaderProps(column.getSortByToggleProps({}))} colSpan={colSpan(column)}>
                  {column.Header && <Header>{column.render("Header")}</Header>}
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  return (
                    <Cell key={idx} {...cell.getCellProps()} colSpan={colSpan(cell.column)}>
                      {cell.render("Cell")}
                    </Cell>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {paginate}
    </div>
  )
}
