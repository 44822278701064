import React, { useMemo } from "react"
import moment from "moment"
import { useTable, useSortBy } from "react-table"

import { Cell, Header, HeaderCell, Table, SmallTextCell, isColumn } from "../common/tables/table"
import { Books_books } from "../../hasura/queries/types/Books"
import { Passage } from "../../hasura/slices/passage"

const defaultColumn: any = {
  Cell: SmallTextCell,
}

interface Props {
  data: Books_books[]
  passages: Passage[]
}

export default function BooksTable(props: Props) {
  const columns = useMemo(
    () => [
      {
        Header: "Created",
        accessor: (b: Books_books) => moment(b.created_at).format("M-D-YY"),
      },
      {
        Header: "Title",
        accessor: (b: Books_books) => b,
        Cell: (b: { value: Books_books }) => {
          return (
            <div onClick={() => window.open(`/book?s=${b.value.seed}&debug`, "_blank")} className="text-s text-link">
              {b.value.title}
            </div>
          )
        },
      },
      {
        Header: "# Passages",
        accessor: (b: Books_books) => props.passages.filter((p) => p.concepts_denormalized?.includes(b.seed)).length,
      },
      {
        Header: "Live",
        accessor: (b: Books_books) => (b.live ? "Yes" : ""),
      },
      {
        Header: "Free",
        accessor: (b: Books_books) => (b.live && b.is_basic ? "Yes" : ""),
      },
      {
        Header: "Unlock Level",
        accessor: (b: Books_books) => b.additional_data?.unlock_level,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: props.data,
      defaultColumn,
      initialState: {
        // @ts-ignore
        sortBy: [
          { id: "Live", desc: true },
          { id: "Unlock Level", desc: false },
        ],
      },
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, "Title") ? 3 : 1)

  return (
    <div>
      <Table style={{ maxWidth: "1300px" }} className="w-100" cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, idx: number) => (
                <HeaderCell noBorder key={idx} {...column.getHeaderProps()} colSpan={colSpan(column)}>
                  {column.Header && <Header>{column.render("Header")}</Header>}
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  return (
                    <Cell key={idx} {...cell.getCellProps()} colSpan={colSpan(cell)}>
                      {cell.render("Cell")}
                    </Cell>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
