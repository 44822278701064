import Select from "react-select"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import BooksTable from "../components/passage/bookTable"
import Layout from "../components/layout"
import PassagesTable from "../components/passage/table"
import Radio from "../components/common/forms/radio"
import SEO from "../components/seo"
import { BookState, bookSelector } from "../hasura/slices/book"
import { ConceptState, conceptSelector } from "../hasura/slices/concept"
import { CurriculumState, curriculumSelector } from "../hasura/slices/curriculum"
import { PassageState, deletePassageAction, fetchPassagesForLibraryAction, passageSelector } from "../hasura/slices/passage"
import { UserState, userSelector } from "../hasura/slices/user"
import { pluralize } from "../lib/helpers"
import { Option } from "../interfaces/option"
import { Books_books } from "../hasura/queries/types/Books"

export default function Passages() {
  const dispatch = useDispatch()

  const { accessToken }: UserState = useSelector(userSelector)
  const { passagesForAdmin }: PassageState = useSelector(passageSelector)
  const { books }: BookState = useSelector(bookSelector)
  const { concepts }: ConceptState = useSelector(conceptSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const [displayBooks, setDisplayBooks] = React.useState(false)
  const [view, setView] = React.useState("approved")
  const [book, setBook] = React.useState<Books_books | null>(null)

  const bookOptions = books.map((b) => ({ label: b.title, value: b.seed }))

  const passages = passagesForAdmin || []

  useEffect(() => {
    if (!accessToken || !curriculum) return

    fetchPassages()
  }, [accessToken, curriculum])

  const fetchPassages = () => {
    if (!accessToken || !curriculum) return

    dispatch(fetchPassagesForLibraryAction(accessToken, curriculum.id))
  }

  const handleDeletePassage = async (id: number) => {
    if (!accessToken) return

    await dispatch(deletePassageAction(accessToken, id))
    fetchPassages()
  }

  const bookOption = bookOptions.find((o) => o.value === book?.seed)

  const filteredPassages = passages
    .filter((p) => {
      if (view === "all") return true
      if (view === "completed") return p.is_complete
      if (view === "approved") return p.status === "done" && !p.is_complete
      if (view === "needs review") return p.status === "review"
    })
    .filter((p) => !book || p.concepts_denormalized?.includes(book.seed))

  return (
    <Layout noStyles>
      <SEO title="Passages" />

      <div style={{ maxWidth: "1300px", paddingBottom: "125px" }} className="mx-auto mt-4 default-width-padding">
        <div className="d-flex justify-content-between">
          <h1 className="garamond text-xxl m-0">
            {displayBooks ? "Books" : "Passages"}{" "}
            <span className="text--gray7 pointer" onClick={() => setDisplayBooks(!displayBooks)}>
              {" "}
              / {displayBooks ? "Passages" : "Books"}
            </span>
          </h1>
        </div>

        {displayBooks ? (
          <BooksTable data={books} passages={passages} />
        ) : (
          <div>
            <div className="d-flex">
              <p className="bold mb-0 text--gray7">Filter</p>

              <div className="ml-3 mt-1">
                <Select
                  className="width-350px"
                  isClearable
                  options={bookOptions}
                  placeholder="Book"
                  value={bookOption || null}
                  onChange={(option: Option | null) => setBook(books.find((b) => b.seed === option?.value) || null)}
                />

                <div className="mt-1 d-flex">
                  <Radio onClick={() => setView("all")} checked={view === "all"} label="All" />
                  <Radio onClick={() => setView("completed")} checked={view === "completed"} label="Completed" />
                  <Radio onClick={() => setView("approved")} checked={view === "approved"} label="Approved" />
                  <Radio onClick={() => setView("needs review")} checked={view === "needs review"} label="Needs Review" />

                  <p className="text-s ml-2 bold text--gray7 mb-0">
                    {pluralize(`${view === "all" ? "" : `${view} `}passage`, filteredPassages.length)}
                  </p>
                </div>
              </div>
            </div>

            <PassagesTable books={books} concepts={concepts || []} data={filteredPassages} handleDeletePassage={handleDeletePassage} />
          </div>
        )}
      </div>
    </Layout>
  )
}
