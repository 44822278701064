import React from "react"

import { Concepts_concepts } from "../../hasura/queries/types/Concepts"
import { includeRightMargin } from "../../lib/helpers"
import { Annotated } from "../../hasura/slices/passage"

interface Props {
  annotated: Annotated
  concepts: Concepts_concepts[]
}

export default function PassageTokens(props: Props) {
  return (
    <p style={{ whiteSpace: "pre-line" }} className="m-0">
      {props.annotated.pretext && <span className="mr-1">{props.annotated.pretext}</span>}

      {props.annotated.tokens.map((token, idx) => {
        if (idx === props.annotated.tokens.length - 1) return null

        return (
          <span
            key={idx}
            className={`
              display-inline
              ${includeRightMargin(props.annotated.tokens, token, idx) ? "mr-1" : ""}
              ${props.concepts.map((c) => c.display_name).includes(token.lemma) ? "bottom-border-secondary" : ""}
          `}
          >
            {token.value}
            {idx === props.annotated.tokens.length - 2 ? props.annotated.tokens[idx + 1].value : ""}
          </span>
        )
      })}

      {props.annotated.posttext && <span className="ml-1">{props.annotated.posttext}</span>}
    </p>
  )
}
