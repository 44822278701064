import React from "react"

interface Props {
  checked: boolean
  onClick: (e: any) => void
  label: string
  dark?: boolean
}

export default (props: Props) => {
  return (
    <label role="button" className={`text-s m-0 mr-3 ${props.dark && "text-dark-bg"}`}>
      <input checked={props.checked} className="data-hj-allow mr-1" onClick={props.onClick} readOnly role="button" type="radio" />
      {props.label}
    </label>
  )
}
